import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnFlex, RowFlex } from '../../../common/components/Layout/Layout'
import NavBar from '../../../common/components/NavBar/NavBar'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import background from '../img/background.svg'
import Logo from '../img/logo.svg?react'

const Container = styled(ColumnFlex)`
  height: 100%;
`

// ${background} needs to be surrounded by double quotes to work with url() with Vite
// prettier-ignore
const PatternContainer = styled(ColumnFlex)`
  background: url("${background}") left top no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const CenteredContainer = styled(RowFlex)`
  flex-wrap: wrap;
  margin: 50px;
  justify-content: center;
`

const LogoContainer = styled(Logo)<{ isMobile: boolean }>`
  margin: auto;
  max-width: 300px;
  height: auto;
  max-width ${(props) => (props.isMobile ? '150px' : '300px')};
`

const TextContainer = styled(ColumnFlex)`
  color: white;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 350px;
  gap: 16px;
`

interface Props {
  message: string
}

const MaintenanceView: React.FC<React.PropsWithChildren<Props>> = ({ message }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { changeLanguage } = useChangeLanguage()

  return (
    <Container>
      <NavBar onLanguageSelect={changeLanguage} variant="nested" />
      <PatternContainer>
        <CenteredContainer>
          <LogoContainer isMobile={isMobile} />
          <TextContainer>
            <Text as="h1" $size={600} $weight="Bold">
              {t('maintenance.header')}
            </Text>
            <Text as="p" $size={400}>
              {message}
            </Text>
          </TextContainer>
        </CenteredContainer>
      </PatternContainer>
    </Container>
  )
}

export default MaintenanceView
