import styled from '@emotion/styled'
import { Primary100 } from '@mehilainen/mds-customer/colors'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../hooks/useBreakpoint'
import { stripTagsAllowBasicHtml } from '../../utils/xss'
import { ColumnFlex, RowFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import ToggleArea from '../ToggleArea/ToggleArea'

import UserPhoneIllustration from './img/user-phone-illustration.svg'

const Heading = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 16px;
`

const Container = styled(ColumnFlex)<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '34px' : '34px 80px')};
  background: ${Primary100};
`

const RowContainer = styled(RowFlex)`
  img {
    object-fit: contain;
    margin-right: 80px;
  }
`

const BackButton = styled(Button)`
  margin-top: 50px;
`

interface Props {
  arrivalInstructions: string
  isOpen: boolean
  setIsOpen(open: boolean): void
}

const ArrivalInstructionsModal: React.FC<React.PropsWithChildren<Props>> = ({
  arrivalInstructions,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <ResponsiveModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby={t('component.arrivalInstructionsModal.label')}
      aria-describedby={t('component.arrivalInstructionsModal.description')}
      maxWidth="md"
      omitDefaultPadding={true}
    >
      <Container isMobile={isMobile}>
        <RowContainer>
          {!isMobile && <img src={UserPhoneIllustration} width="300px" height="300px" alt="" />}
          <ColumnFlex>
            <Heading>{t('component.arrivalInstructionsModal.heading')}</Heading>
            <ToggleArea
              maxHeight={200}
              moreLabel={t('common.showMore')}
              lessLabel={t('common.showLess')}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: stripTagsAllowBasicHtml(arrivalInstructions),
                }}
              />
            </ToggleArea>
            <BackButton variant="outlined" onClick={() => setIsOpen(false)}>
              {t('common.back')}
            </BackButton>
          </ColumnFlex>
        </RowContainer>
      </Container>
    </ResponsiveModal>
  )
}

export default ArrivalInstructionsModal
