import styled from '@emotion/styled'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import Fieldset from './Fieldset'
import { Gender } from './types'

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`

interface Props {
  name: string
  value: Gender
  handleChange: (event: React.ChangeEvent<{ value: Gender }>) => void
}

const GenderSelect: React.FC<React.PropsWithChildren<Props>> = ({ name, value, handleChange }) => {
  const { t } = useTranslation()

  return (
    <Fieldset
      legend={t('component.filterOptions.genderSelect.legend')}
      expanded={value !== Gender.UNSPECIFIED}
    >
      <StyledRadioGroup>
        {Object.values(Gender).map((gender) => (
          <FormControlLabel
            key={`genderSelect-RadioButton-${gender}`}
            control={
              <Radio
                id={`genderSelect-gender-${gender}-radio`}
                checked={gender === value}
                onChange={() => {
                  handleChange({
                    target: {
                      name,
                      value: gender,
                    },
                  } as unknown as React.ChangeEvent<{ value: Gender }>)
                }}
              />
            }
            label={t(`component.filterOptions.genderSelect.gender.${gender}`)}
            value={gender}
          />
        ))}
      </StyledRadioGroup>
    </Fieldset>
  )
}

export default GenderSelect
