import { Clock } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationStyle } from '../../../__generated__/api'
import { VisuallyHidden } from '../Layout/Layout'
import Notification from '../Notification/Notification'

interface Props {
  releaseDateTime?: Dayjs
  className?: string
  appointmentDate?: string
  extendLock: () => void
  extended: boolean
}

const SHOW_WARNING_THRESHOLD = 120 // show warning when 2 minutes of lock left
const UPDATE_INTERVAL = 1000 // update visually every second
const ARIA_UPDATE_INTERVAL = 20000 // update aria text every 20 seconds
const EXTEND_ENABLED_THRESHOLD = 1.5 // enable lock extension when over 1.5 hours to appointment

const LockReleaseWarning: React.FC<React.PropsWithChildren<Props>> = ({
  releaseDateTime,
  className,
  appointmentDate,
  extendLock,
  extended,
}) => {
  const { t } = useTranslation()
  const [secondsLeft, setSecondsLeft] = useState<number>()
  const [ariaText, setAriaText] = useState<string>('')
  const [titleText, setTitleText] = useState<string>('')
  const [bodyText, setBodyText] = useState<string>('')
  const lastAriaUpdate = useRef<Date | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      if (releaseDateTime) {
        const localSecondsLeft = releaseDateTime.diff(dayjs(), 'seconds')
        setSecondsLeft(localSecondsLeft)

        let timeLeft = null

        if (localSecondsLeft >= 0) {
          const minutes = Math.floor(localSecondsLeft / 60)
          const seconds = localSecondsLeft - minutes * 60
          if (minutes > 0) {
            timeLeft = `${minutes}:${String(seconds).padStart(2, '0')}min`
          } else {
            timeLeft = `${seconds}s`
          }
        }

        setTitleText(
          timeLeft
            ? t('component.lockReleaseWarning.lockedHeading', { timeLeft })
            : t('component.lockReleaseWarning.releasedHeading')
        )

        setBodyText(
          timeLeft
            ? t('component.lockReleaseWarning.lockedText')
            : t('component.lockReleaseWarning.releasedText')
        )
      }
    }, UPDATE_INTERVAL)

    return () => clearInterval(interval)
  }, [releaseDateTime, t])

  // Update aria live area text slower than the visible text so we don't spam the screen reader
  useEffect(() => {
    if (
      lastAriaUpdate.current === null ||
      dayjs().diff(lastAriaUpdate.current) > ARIA_UPDATE_INTERVAL
    ) {
      lastAriaUpdate.current = new Date()
      setAriaText(`${titleText} ${bodyText}`)
    }
  }, [bodyText, titleText])

  if (!releaseDateTime || secondsLeft === undefined || secondsLeft > SHOW_WARNING_THRESHOLD) {
    return null
  }

  const enableExtend =
    !extended &&
    secondsLeft >= 0 &&
    dayjs(appointmentDate).isAfter(dayjs().add(EXTEND_ENABLED_THRESHOLD, 'hours'))

  return (
    <>
      <VisuallyHidden aria-live="assertive">{ariaText}</VisuallyHidden>
      <Notification
        style={NotificationStyle.Warning}
        className={className}
        icon={<Clock />}
        title={titleText}
        action={
          enableExtend && (
            <Button onClick={extendLock} variant="outlined" size="small" color="warning">
              {t('component.lockReleaseWarning.extendText')}
            </Button>
          )
        }
        role="timer"
      >
        {bodyText}
      </Notification>
    </>
  )
}

export default LockReleaseWarning
