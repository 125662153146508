import { Gray700, Primary100, Primary500 } from '@mehilainen/mds-customer/colors'
import { PollH } from '@mehilainen/mds-customer/icons'
import { Avatar } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { scale } from '../../utils/scale'
import { CenteredColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const NoEvents: React.FC = () => {
  const { t } = useTranslation()
  return (
    <CenteredColumnFlex $gap={scale(1)}>
      <Avatar sx={{ bgcolor: Primary100, width: '48px', height: '48px' }}>
        <PollH htmlColor={Primary500} sx={{ width: '28px', height: '28px' }} />
      </Avatar>
      <Text $size={400} $weight="Medium" $height="Medium">
        {t('component.noEvents.title')}
      </Text>
      <Text
        $size={300}
        $weight="Regular"
        $height="Medium"
        $color={Gray700}
        $center
        style={{ maxWidth: '200px' }}
      >
        {t('component.noEvents.description')}
      </Text>
    </CenteredColumnFlex>
  )
}

export default NoEvents
