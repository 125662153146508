import styled from '@emotion/styled'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import Fieldset from './Fieldset'

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 8px;
`

interface Props {
  durations: number[]
  name: string
  value: number[]
  handleChange: (event: React.ChangeEvent<{ value: number[] }>) => void
}

const DurationSelect: React.FC<React.PropsWithChildren<Props>> = ({
  durations,
  name,
  value,
  handleChange,
}) => {
  const { t } = useTranslation()

  return (
    <Fieldset
      legend={t('component.filterOptions.durationSelect.legend')}
      tooltip={t('component.filterOptions.durationSelect.tooltip')}
      expanded={value.length > 0}
    >
      <StyledFormGroup>
        {durations.map((duration) => (
          <FormControlLabel
            key={`durationSelect-duration-${duration}`}
            control={
              <StyledCheckbox
                id={`durationSelect-duration-${duration}-checkbox`}
                checked={value.includes(duration)}
              />
            }
            label={duration.toString() + ' ' + t(`component.filterOptions.durationSelect.label`)}
            onChange={() =>
              handleChange({
                target: {
                  name,
                  value: !value.includes(duration)
                    ? [...value, duration]
                    : value.filter((type) => type !== duration),
                },
              } as unknown as React.ChangeEvent<{ value: number[] }>)
            }
          />
        ))}
      </StyledFormGroup>
    </Fieldset>
  )
}

export default DurationSelect
