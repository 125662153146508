import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { baseTypography } from '@mehilainen/mds-customer/typography'

export const Bold = styled.span`
  font-weight: 500;
`

export const Black = styled.span`
  font-size: 21px;
  font-weight: 500;

  &:first-letter {
    text-transform: capitalize;
  }
`

export type FontSize =
  | 100
  | 200
  | 300
  | 400
  | 450
  | 500
  | 550
  | 600
  | 700
  | 800
  | 850
  | 900
  | 1000
  | 1100

export type FontWeight = keyof typeof baseTypography.weight

export type FontHeight = keyof typeof baseTypography.height

export const Text = styled.span<{
  href?: string
  target?: string
  rel?: string
  htmlFor?: string
  $size?: FontSize
  $height?: FontHeight
  $weight?: FontWeight
  $color?: string
  $center?: boolean
  $uppercase?: boolean
  $capitalize?: boolean
}>`
  font-weight: ${(props) => baseTypography.weight[props.$weight ?? 'Regular']};
  line-height: ${(props) => baseTypography.height[props.$height ?? 'Medium']};
  font-size: ${(props) => baseTypography.size[props.$size ?? 400].rem};
  color: ${(props) => props.$color ?? 'inherit'};

  :is(button) {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  ${(props) =>
    props.$center &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.$uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${(props) =>
    props.$capitalize &&
    css`
      text-transform: capitalize;
    `}
`
