import { Times } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'

import Chip from '../../../common/components/Chip/Chip'
import InsuranceSelectModal from '../../../common/components/InsuranceSelectModal/InsuranceSelectModal'
import { useInsuranceName } from '../../../common/hooks/useInsurance'
import { insuranceModalOpenAtom, preSelectedInsuranceIdAtom } from '../../../state/common/atoms'
import { selectedInsuranceSelector } from '../../../state/common/selectors'

interface Props {
  setStatusMessage(message: string): void
}

const SearchFilterInsuranceChip: React.FC<React.PropsWithChildren<Props>> = ({
  setStatusMessage,
}) => {
  const { t } = useTranslation()

  const preSelectedInsuranceId = useRecoilValue(preSelectedInsuranceIdAtom)
  const [selectedInsurance, setSelectedInsurance] = useRecoilState(selectedInsuranceSelector)
  const { name: insuranceName } = useInsuranceName(selectedInsurance?.id)

  const [open, setOpen] = useRecoilState(insuranceModalOpenAtom)

  return (
    <>
      {selectedInsurance === null ? (
        <Chip
          variant="important"
          ariaLabel={t('component.searchFilterChips.insurance')}
          label={t('component.searchFilterChips.insurance')}
          onClick={() => setOpen(true)}
          order="icon-first"
          dataCy="searchFilterInsuranceChip-chip"
        />
      ) : (
        <Chip
          variant="important"
          ariaLabel={`${t('common.removeFilter')} ${insuranceName}`}
          label={insuranceName}
          onClick={() => {
            setStatusMessage(`${t('common.filterRemoved')} ${insuranceName}`)
            setSelectedInsurance(null)
          }}
          icon={<Times />}
        />
      )}
      <InsuranceSelectModal
        open={open}
        onSelect={(id, paymentType) => {
          setOpen(false)
          setSelectedInsurance(
            id ? (id === 'other' ? { id: 'other', paymentType } : { id: id, paymentType }) : null
          )
        }}
        onCancel={() => setOpen(false)}
        selectedInsurance={selectedInsurance?.id ?? null}
        preSelectedInsurance={preSelectedInsuranceId}
      />
    </>
  )
}

export default SearchFilterInsuranceChip
