import { Global } from '@emotion/react'
import { theme as mehilainenTheme } from '@mehilainen/mds-customer'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { ReactNode, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom'
import { RecoilRoot, useSetRecoilState } from 'recoil'

import Routes from './Routes'
import GlobalStyle from './common/components/GlobalStyle/GlobalStyle'
import { TabTitleContext } from './common/components/TabTitle/TabTitleContext'
import { ChangeLanguageProvider } from './common/hooks/useChangeLanguage'
import { LoginStateProvider } from './common/hooks/useLoginState'
import { MobileRelayProvider } from './common/hooks/useMobileRelay'
import { NodesProvider } from './common/hooks/useNode'
import { queryClientProvider } from './common/services/queryClientProvider'
import Sentry from './common/services/sentry'
import CallbackErrorBoundary from './common/utils/error/CallbackErrorBoundary'
import GenericErrorBoundary from './common/utils/error/GenericErrorBoundary'
import MaintenanceErrorBoundary from './common/utils/error/MaintenanceErrorBoundary'
import ReserveAppointmentNotFoundErrorBoundary from './common/utils/error/ReserveAppointmentNotFoundErrorBoundary'
import { SearchTargetProvider } from './domain/search/hooks/useSearchTarget'
import { isOHCAtom } from './state/common/atoms'

const AppContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <RecoilRoot>
      <Sentry.RecoilTransactionObserver />
      <ThemeProvider theme={mehilainenTheme}>
        <QueryClientProvider client={queryClientProvider.getClient()}>
          {process.env.REACT_APP_ENABLE_QUERY_DEV_TOOLS && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
          <Global styles={GlobalStyle} />
          <GenericErrorBoundary>
            <MaintenanceErrorBoundary>
              <TabTitleContext.Provider value={document.title}>
                <LoginStateProvider>
                  <NodesProvider>
                    <SearchTargetProvider>
                      <ChangeLanguageProvider>
                        <MobileRelayProvider>
                          <ReserveAppointmentNotFoundErrorBoundary>
                            <CallbackErrorBoundary>{children}</CallbackErrorBoundary>
                          </ReserveAppointmentNotFoundErrorBoundary>
                        </MobileRelayProvider>
                      </ChangeLanguageProvider>
                    </SearchTargetProvider>
                  </NodesProvider>
                </LoginStateProvider>
              </TabTitleContext.Provider>
            </MaintenanceErrorBoundary>
          </GenericErrorBoundary>
        </QueryClientProvider>
      </ThemeProvider>
    </RecoilRoot>
  )
}

const OHCComponent: React.FC<React.PropsWithChildren<RouteComponentProps>> = () => {
  const setIsOHC = useSetRecoilState(isOHCAtom)
  setIsOHC(true)
  return <Routes basePath="/tyoterveys" />
}

const App = (): JSX.Element => {
  Sentry.init()

  useEffect(() => {
    return () => {
      Sentry.close()
    }
  }, [])

  return (
    <Router>
      <AppContextProvider>
        <Switch>
          <Route path="/av3">
            {(props) => {
              return (
                <Redirect
                  to={{
                    ...props.location,
                    pathname: props.location.pathname.replace('/av3', ''),
                  }}
                />
              )
            }}
          </Route>
          <Route
            exact
            path={`/tep`}
            render={() => {
              return <Redirect to="/tyoterveys" />
            }}
          />
          <Route path="/tyoterveys" component={OHCComponent} />
          <Route
            path="/"
            render={() => {
              return <Routes basePath="" />
            }}
          />
        </Switch>
      </AppContextProvider>
    </Router>
  )
}

export default App
