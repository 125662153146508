import Cookies from 'js-cookie'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { jwtKey, mehiAvCookie } from '../../constants'
import { preSelectedUserOmIdAtom } from '../../state/reserve/atoms'
import {
  LoginStatus,
  loginStatusAtom,
  ohcAllowedStatusAtom,
  teliaJWTAtom,
} from '../../state/user/atoms'
import api from '../services/api'
import authenticationService from '../services/authenticationService'
import { filterExternalUrlParameters, getAV3HomeUrl } from '../utils/url'
import { getTimeToJwtExp, isJwtValid } from '../utils/utils'

if (typeof window !== 'undefined') {
  setInterval(async () => {
    if (Cookies.get(mehiAvCookie)) {
      try {
        await api.v1.heartbeat()
      } catch {
        // NO-OP
      }
    }
  }, 15000)
}

interface LoginStateContextType {
  loginStatus: LoginStatus
  logout(): void
  setApiToken(token: string): void
}

const LoginStateContext = createContext<LoginStateContextType>({
  loginStatus: undefined,
  logout: () => {
    /* placeholder */
  },
  setApiToken: () => {
    /* placeholder */
  },
})

export const LoginStateProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const location = useLocation()
  const omIdParam = new URLSearchParams(location.search).get('omUid')
  const setPreSelectedUser = useSetRecoilState(preSelectedUserOmIdAtom)
  if (omIdParam) {
    setPreSelectedUser(parseInt(omIdParam))
  }
  const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom)
  const [teliaJWT, setTeliaJWT] = useRecoilState(teliaJWTAtom)
  const setOhcAllowedStatus = useSetRecoilState(ohcAllowedStatusAtom)

  const logout = useCallback(async () => {
    if (Cookies.get(mehiAvCookie)) {
      await authenticationService.logout()
    }
    sessionStorage.removeItem(jwtKey)
    setLoginStatus(undefined)
    setOhcAllowedStatus(null)

    window.location.assign(getAV3HomeUrl())
  }, [setLoginStatus, setOhcAllowedStatus])

  const setApiToken = useCallback(async (token: string) => {
    if (Cookies.get(mehiAvCookie)) {
      await authenticationService.logout()
    }
    api.http.setJwt(token)
    sessionStorage.setItem(jwtKey, token)
  }, [])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined
    if (teliaJWT) {
      timeout = setTimeout(
        () => {
          authenticationService
            .refreshToken(teliaJWT)
            .then((newToken) => {
              setTeliaJWT(newToken)
            })
            .catch(() => {
              // TODO: Should we log the user out here if refresh fails?
              setTeliaJWT(null)
            })
        },
        getTimeToJwtExp(teliaJWT) - 10 * 1000 // Attempt refresh in 0-10s before expiry
      )
    }
    return () => timeout && clearTimeout(timeout)
  }, [teliaJWT, setTeliaJWT])

  useEffect(() => {
    const cookie = Cookies.get('av3_imp_token')
    if (cookie && !Boolean(sessionStorage.getItem(jwtKey))) {
      const urlParams = new URLSearchParams(window.location.search)
      // token is passed as cookie if user is redirected (instead of opening new tab) from Mehidoc
      setApiToken(cookie)
      Cookies.remove('av3_imp_token')
      if (urlParams.get('parseurlParams')) {
        parseRedirect(urlParams.get('parseurlParams'))
      }
    }
  }, [setApiToken])

  useEffect(() => {
    const interval = setInterval(() => {
      const hasJwt =
        Boolean(sessionStorage.getItem(jwtKey)) && isJwtValid(sessionStorage.getItem(jwtKey))
      const hasImpersonationJwt =
        hasJwt &&
        !sessionStorage.getItem(jwtKey)?.startsWith('Test') &&
        !sessionStorage.getItem(jwtKey)?.startsWith('External') &&
        !sessionStorage.getItem(jwtKey)?.startsWith('Partner')
      const hasTestJwt = hasJwt && sessionStorage.getItem(jwtKey)?.startsWith('Test')
      const hasExtJwt =
        hasJwt &&
        (sessionStorage.getItem(jwtKey)?.startsWith('External') ||
          sessionStorage.getItem(jwtKey)?.startsWith('Partner'))
      const hasCookieAuth = Cookies.get(mehiAvCookie)

      if (!loginStatus) {
        if (hasCookieAuth || hasTestJwt) {
          setLoginStatus('authenticated')
        } else if (hasImpersonationJwt) {
          setLoginStatus('impersonated')
        } else if (hasExtJwt) {
          setLoginStatus('external')
        }
      }

      // Ensure API instance is in sync with jwt
      if (hasJwt) {
        api.http.setJwt(sessionStorage.getItem(jwtKey)!)
      }

      // Marked as logged in, but session/cookie expired
      if (loginStatus && !hasJwt && !hasCookieAuth) {
        sessionStorage.removeItem(jwtKey)
        setLoginStatus(undefined)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [loginStatus, setLoginStatus])

  useEffect(() => {
    function handleEvent(message: MessageEvent) {
      if (message.data) {
        // Token is coming from Cypress
        if (message.origin === 'Cypress') {
          const token = message.data
          if (!isJwtValid(token)) {
            return
          }
          setApiToken(token)
          return
        }

        // Token is coming from Mehidoc app
        if (message.origin === 'mehidoc-app') {
          const token = message.data
          if (!isJwtValid(token)) {
            return
          }
          setApiToken(token)
          return
        }

        // Token is coming from Mehidoc desktop (Aspa etc.)
        const messageOriginURL = new URL(message.origin)

        const hostnameMatches =
          (messageOriginURL.hostname.includes('mehidoc') &&
            messageOriginURL.hostname.endsWith('mehilainen.fi')) ||
          (message.origin.includes('localhost') && window.origin.includes('localhost'))

        if (
          (message.data.mehiToken || message.data.extToken) &&
          message.source === window.opener &&
          hostnameMatches
        ) {
          const token = message.data.mehiToken
            ? message.data.mehiToken
            : message.data.extToken
            ? `External ${message.data.extToken}`
            : undefined

          if (!isJwtValid(token)) {
            return
          }

          setApiToken(token)
          window.opener.postMessage('TOKEN_TRANSFERRED', message.origin)

          if (message.data.parseUrlParams) {
            parseRedirect(message.data.parseUrlParams)
          }
        }
      }
    }
    window.addEventListener('message', handleEvent)

    return () => window.removeEventListener('message', handleEvent)
  }, [setApiToken])

  const memoed = useMemo(
    () => ({
      loginStatus,
      logout,
      setApiToken,
    }),
    [loginStatus, logout, setApiToken]
  )

  return <LoginStateContext.Provider value={memoed}>{children}</LoginStateContext.Provider>
}

const useLoginState = (onSessionExpire?: () => void): LoginStateContextType => {
  const { loginStatus, logout, setApiToken } = useContext(LoginStateContext)
  const prevLoginStatusRef = useRef<LoginStatus>(loginStatus)

  useEffect(() => {
    if (loginStatus === undefined && prevLoginStatusRef.current !== undefined) {
      onSessionExpire?.()
    }
    prevLoginStatusRef.current = loginStatus
  }, [loginStatus, onSessionExpire])

  return { loginStatus, logout, setApiToken }
}

const parseRedirect = async (parseUrlParams: string | null): Promise<void> => {
  const apiUrl = new URL(process.env.REACT_APP_API || '')
  apiUrl.pathname = '/parse/' + parseUrlParams
  fetch(apiUrl.toString(), {
    method: 'GET',
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => {
      const filteredParams = filterExternalUrlParameters(window.location.search).toString()
      const url = response.url.includes('?')
        ? response.url + '&' + filteredParams
        : response.url + '?' + filteredParams
      // eslint-disable-next-line no-console
      console.debug('response', response, url)
      window.location.replace(url)
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Failed to parse URL parameters', error)
    })
}

export default useLoginState
