import styled from '@emotion/styled'
import {
  Attention100,
  Attention800,
  Error100,
  Info100,
  Info800,
  Primary500,
  White,
} from '@mehilainen/mds-customer/colors'
import { Alert, AlertColor, AlertProps, AlertTitle } from '@mui/material'
import type React from 'react'

import { NotificationStyle } from '../../../__generated__/api'

export const HeadingTextColor: Record<NotificationStyle, string> = {
  [NotificationStyle.Info]: Info800,
  [NotificationStyle.Raise]: Primary500,
  [NotificationStyle.WalkIn]: Primary500,
  [NotificationStyle.Warning]: Attention800,
  [NotificationStyle.Error]: Attention800,
}

export const BackgroundColor: Record<NotificationStyle, string> = {
  [NotificationStyle.Info]: Info100,
  [NotificationStyle.Raise]: White,
  [NotificationStyle.WalkIn]: White,
  [NotificationStyle.Warning]: Attention100,
  [NotificationStyle.Error]: Error100,
}

const IconContainer = styled.span<{ notificationStyle: NotificationStyle }>`
  filter: ${({ notificationStyle }) => IconFilter[notificationStyle]};
`

export const mapNotificationStyles = (style: NotificationStyle): AlertColor => {
  switch (style) {
    case NotificationStyle.Info:
      return 'info'
    case NotificationStyle.Raise:
      return 'success'
    case NotificationStyle.WalkIn:
      return 'success'
    case NotificationStyle.Warning:
      return 'warning'
    case NotificationStyle.Error:
      return 'error'
  }
}

const IconFilter: Record<NotificationStyle, string> = {
  [NotificationStyle.Info]:
    'brightness(0) saturate(100%) invert(10%) sepia(27%) saturate(6377%) hue-rotate(188deg) brightness(98%) contrast(103%)',
  [NotificationStyle.Raise]:
    'brightness(0) saturate(100%) invert(27%) sepia(89%) saturate(1000%) hue-rotate(112deg) brightness(100%) contrast(101%)',
  [NotificationStyle.WalkIn]:
    'brightness(0) saturate(100%) invert(27%) sepia(89%) saturate(1000%) hue-rotate(112deg) brightness(100%) contrast(101%)',
  [NotificationStyle.Warning]:
    'brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(2094%) hue-rotate(38deg) brightness(94%) contrast(102%)',
  [NotificationStyle.Error]:
    'brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(2094%) hue-rotate(38deg) brightness(94%) contrast(102%)',
}

interface Props {
  style: NotificationStyle
  className?: string
  icon?: AlertProps['icon']
  action?: AlertProps['action']
  title?: string
  isExternalIcon?: boolean
  role?: string
}

const Notification: React.FC<React.PropsWithChildren<Props>> = ({
  style,
  children,
  className,
  icon,
  action,
  title,
  isExternalIcon,
  role,
}) => {
  return (
    <Alert
      className={className}
      severity={mapNotificationStyles(style)}
      icon={
        icon &&
        (isExternalIcon ? <IconContainer notificationStyle={style}>{icon}</IconContainer> : icon)
      }
      action={action}
      role={role}
    >
      {Boolean(title) && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  )
}

export default Notification
