import styled from '@emotion/styled'
import { White, Primary450 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentType } from '../../../__generated__/api'
import { AppointmentDetailsBackground } from '../../../constants'
import dayjs from '../../utils/dayjs/dayjs'
import { scale } from '../../utils/scale'
import { formatCallbackTime, formatDate4, formatDateAndTime } from '../../utils/text'
import { CenteredColumnFlex, ColumnFlex } from '../Layout/Layout'
import AppointmentSpecialistDetails from '../Tile/AppointmentSpecialistDetails'
import { Text } from '../Typography/Typography'

const TextBlock = styled(ColumnFlex)`
  gap: ${scale(1)};
  text-align: center;
`

interface AppointmentDetailsProps {
  appointmentType: AppointmentType
  specialistName?: string
  specialistTitle?: string
  image?: string | null
  locationName?: string
  locationAddress?: string
  locationPostalCode?: string
  locationPostalDistrict?: string
  time: string
  duration: number
  isResourceList?: boolean
  displayInstructions?: boolean
  appointmentTitle?: string | null
  instructionsComponent?: React.ReactNode
  addToCalendarComponent?: React.ReactNode
  onSpecialistDetailsClick?(): void
}

const Container = styled(CenteredColumnFlex)`
  position: relative;
  overflow: hidden;
  padding: 32px 24px 24px 24px;
  gap: ${scale(2)};
  background: ${AppointmentDetailsBackground};
  border-radius: 8px;
`

const AppointmentDetailsTitle = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px 6px 12px;
  color: ${White};
  background: ${Primary450};
  border-radius: 0 0 8px 0;
`

const AppointmentDetails: React.FC<React.PropsWithChildren<AppointmentDetailsProps>> = ({
  appointmentType,
  time,
  duration,
  specialistName,
  specialistTitle,
  image,
  isResourceList,
  locationPostalDistrict,
  locationName,
  locationAddress,
  locationPostalCode,
  displayInstructions = false,
  appointmentTitle,
  instructionsComponent,
  addToCalendarComponent,
  onSpecialistDetailsClick,
}) => {
  const { t } = useTranslation()

  const instructions =
    appointmentType === AppointmentType.Video
      ? t('component.appointmentDetails.instructions.video')
      : appointmentType === AppointmentType.Phone
      ? t('component.appointmentDetails.instructions.phone')
      : ''

  return (
    <Container data-cy="appointmentDetails">
      <AppointmentDetailsTitle $size={200}>
        {t('component.typeSelect.reservation')}
      </AppointmentDetailsTitle>
      {specialistName && specialistTitle && (
        <AppointmentSpecialistDetails
          name={specialistName}
          title={specialistTitle}
          image={image}
          isResourceList={isResourceList}
          onClick={onSpecialistDetailsClick}
          variant="M"
        />
      )}
      <TextBlock>
        <Text $size={300} $height="Medium" $weight="Medium">
          {appointmentType === AppointmentType.Callback ? (
            <div>
              <p>{formatDate4(dayjs(time))}</p>
              <p>
                {time && formatCallbackTime(dayjs(time)) !== ''
                  ? formatCallbackTime(dayjs(time))
                  : t('component.simpleAppointmentDetails.timeframeSubstitute')}
              </p>
            </div>
          ) : (
            formatDateAndTime(dayjs(time), t)
          )}
        </Text>
        <Text $size={300} $height="Medium" $weight="Regular">
          {appointmentTitle}
          {appointmentTitle ? ', ' : ''}
          {appointmentType !== AppointmentType.Callback && (
            <span data-cy="appointment-duration">{Math.round(duration)} min</span>
          )}
        </Text>
        {addToCalendarComponent}
      </TextBlock>
      <TextBlock data-cy="locationDetails">
        {appointmentType === AppointmentType.Clinic &&
        locationName &&
        locationAddress &&
        locationPostalCode &&
        locationPostalDistrict ? (
          <>
            <Text $size={300} $height="Medium" $weight="Medium">
              {locationName}
            </Text>
            <Text $size={300} $height="Medium" $weight="Regular">
              {locationAddress}
            </Text>
            <Text
              $size={300}
              $height="Medium"
              $weight="Regular"
            >{`${locationPostalCode} ${locationPostalDistrict}`}</Text>
          </>
        ) : (
          <Text $size={300} $height="Medium" $weight="Medium">
            {t(`component.appointmentDetails.appointmentType.${appointmentType}`)}
          </Text>
        )}
        {appointmentType !== AppointmentType.Clinic && displayInstructions && (
          <Text $size={300} $height="Medium" $weight="Regular">
            {instructions}
          </Text>
        )}
        {instructionsComponent}
      </TextBlock>
    </Container>
  )
}

export default AppointmentDetails
