import equal from 'fast-deep-equal'
import * as qs from 'qs'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  AppointmentType,
  LanguageResult,
  PatientAgeGroup,
  SearchNodeLinkingType,
  SupportedLanguage,
  TimeRange,
} from '../../../__generated__/api'
import { AppointmentDuration } from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { AppointmentListVariant } from '../../../common/components/AppointmentList/AppointmentListToggle'
import { Gender } from '../../../common/components/FilterOptions/types'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import dayjs from '../../../common/utils/dayjs/dayjs'
import { externalUrlParameters } from '../../../common/utils/url'
import { InsurancePaymentType, selectedInsuranceSelector } from '../../../state/common/selectors'
import {
  AppointmentSearchMode,
  appointmentSearchModeAtom,
  defaultAppointmentSearchMode,
  defaultSelectedAppointmentListVariant,
  defaultSelectedAppointmentTypes,
  defaultSelectedDate,
  defaultSelectedGender,
  defaultSelectedLanguage,
  defaultSelectedLocation,
  defaultSelectedPatientAgeGroup,
  defaultSelectedTimeRanges,
  searchTargetRestoredAtom,
  isUserSelectedDateAtom,
  isUserSelectedNodeAtom,
  selectedAppointmentListVariantAtom,
  selectedAppointmentTypesAtom,
  selectedDateAtom,
  selectedGenderAtom,
  selectedLanguageAtom,
  selectedNodeLocationAtom,
  selectedPatientAgeGroupAtom,
  selectedTimeRangesAtom,
  selectedStandardScheduleOptionAtom,
  defaultSelectedStandardScheduleOption,
  userSelectedAppointmentTypesAtom,
} from '../../../state/search/atoms'
import { getDefaultNode } from '../../../state/search/selectors'

import { useNodeLinking } from './useNodeLinking'
import useSearchTarget, { SearchTargetValue } from './useSearchTarget'
export const locationParamKey = 'lo'

const useSearchHistory = (): void => {
  const history = useHistory()

  const [nodeLinkingId, setNodeLinkingId] = useState<number | string | null>(null)
  useNodeLinking(nodeLinkingId, SearchNodeLinkingType.Etns)

  const { searchTarget, setSearchTarget } = useSearchTarget()
  const [isUserSelectedNode, setIsUserSelectedNode] = useRecoilState(isUserSelectedNodeAtom)
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedNodeLocationAtom)
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(selectedLanguageAtom)
  const [selectedGender, setSelectedGender] = useRecoilState(selectedGenderAtom)
  const [selectedTimeRanges, setSelectedTimeRanges] = useRecoilState(selectedTimeRangesAtom)
  const [selectedPatientAgeGroup, setSelectedPatientAgeGroup] = useRecoilState(
    selectedPatientAgeGroupAtom
  )
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom)
  const [isUserSelectedDate, setIsUserSelectedDateAtom] = useRecoilState(isUserSelectedDateAtom)
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useRecoilState(
    selectedAppointmentTypesAtom
  )
  const setSelectedUserAppointmentTypes = useSetRecoilState(userSelectedAppointmentTypesAtom)

  const [selectedAppointmentListVariant, setSelectedAppointmentListVariant] = useRecoilState(
    selectedAppointmentListVariantAtom
  )

  const [appointmentSearchMode, setAppointmentSearchMode] =
    useRecoilState(appointmentSearchModeAtom)

  const [selectedInsurance, setSelectedInsurance] = useRecoilState(selectedInsuranceSelector)

  const [initialSearchHistoryRestored, setInitialSearchHistoryRestored] = useState(false)
  const setSearchTargetRestored = useSetRecoilState(searchTargetRestoredAtom)
  const { currentLanguage, changeLanguage } = useChangeLanguage()

  const defaultNode = useRecoilValue(getDefaultNode)

  const whiteListedSearch = useRef<qs.ParsedQs | null>(null)

  const [selectedStandardScheduleOption, setSelectedStandardScheduleOption] = useRecoilState(
    selectedStandardScheduleOptionAtom
  )

  useEffect(() => {
    const searchString = history.location.search.slice(1)
    if (searchString !== '') {
      const parsed = qs.parse(searchString, { arrayLimit: 1000 })
      whiteListedSearch.current = getWhitelistedSearch(parsed)
      const _ageGroups = {
        ...(parsed.pag as unknown as PatientAgeGroup),
      }
      const ageGroups = _ageGroups.id ? { ..._ageGroups, id: Number(_ageGroups.id) } : null

      parsed.g && setSelectedGender(parsed.g as Gender)
      parsed.s && setIsUserSelectedNode(true)
      parsed.lo && setSelectedLocation(parsed.lo as unknown as string[])
      parsed.tr && setSelectedTimeRanges(parsed.tr as unknown as TimeRange[])
      ageGroups && setSelectedPatientAgeGroup(ageGroups)
      parsed.la && setSelectedLanguage(parsed.la as unknown as LanguageResult)
      parsed.d && setSelectedDate(dayjs(parsed.d as string))
      parsed.d && setIsUserSelectedDateAtom(true)
      parsed.ty && setSelectedAppointmentTypes(parsed.ty as unknown as AppointmentType[])
      parsed.ty && setSelectedUserAppointmentTypes(parsed.ty as unknown as AppointmentType[])
      parsed.lv && setSelectedAppointmentListVariant(parsed.lv as unknown as AppointmentListVariant)
      parsed.lang && changeLanguage(parsed.lang as SupportedLanguage)
      parsed.e && setNodeLinkingId(String(parsed.e))
      parsed.p && setSearchTarget({ id: Number(parsed.p), value: SearchTargetValue.Practitioner })
      parsed.s && setSearchTarget({ id: String(parsed.s), value: SearchTargetValue.Node })
      parsed.in !== undefined &&
        setSelectedInsurance(
          parsed.in.length
            ? {
                id: parsed.in === 'other' ? 'other' : Number(parsed.in),
                paymentType: parsed.inpt as InsurancePaymentType,
              }
            : undefined
        )
      parsed.mode && setAppointmentSearchMode(parsed.mode as unknown as AppointmentSearchMode)
      parsed.so &&
        setSelectedStandardScheduleOption(
          isNaN(parseInt(parsed.so as string)) // Value can be a number or a string
            ? (parsed.so as AppointmentDuration)
            : (parseInt(parsed.so as string) as AppointmentDuration)
        )
      //TODO: Remove session storage implementation when AV3-2652 is implemented
      if (parsed.sourceService) {
        sessionStorage.setItem('av3_sourceService', String(parsed.sourceService))
      }
    }
    setInitialSearchHistoryRestored(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initialSearchHistoryRestored && Boolean(searchTarget.id)) {
      setSearchTargetRestored(true)
    }
  }, [initialSearchHistoryRestored, searchTarget, setSearchTargetRestored])

  // Serialize relevant state to url query string
  useEffect(() => {
    history.replace({
      search: qs.stringify({
        ...Object.assign(
          {},
          whiteListedSearch.current ?? {},
          ...[
            [selectedLocation, defaultSelectedLocation, locationParamKey],
            [selectedGender, defaultSelectedGender, 'g'],
            [selectedTimeRanges, defaultSelectedTimeRanges, 'tr'],
            [selectedAppointmentTypes, defaultSelectedAppointmentTypes, 'ty'],
            [selectedAppointmentListVariant, defaultSelectedAppointmentListVariant, 'lv'],
            [selectedPatientAgeGroup, defaultSelectedPatientAgeGroup, 'ag'],
            searchTarget.value === SearchTargetValue.Practitioner
              ? [searchTarget.id, undefined, 'p']
              : [searchTarget.id, defaultNode, 's', isUserSelectedNode],
            [currentLanguage, SupportedLanguage.Fi, 'lang'],
            [selectedLanguage, defaultSelectedLanguage, 'la'],
            [appointmentSearchMode, defaultAppointmentSearchMode, 'mode'],
            [selectedStandardScheduleOption, defaultSelectedStandardScheduleOption, 'so'],
          ].map(([value, defaultValue, key, specialRule]) => ({
            ...(!equal(value, defaultValue) || specialRule ? { [key as string]: value } : {}),
          }))
        ),
        ...(!selectedDate.isSame(defaultSelectedDate, 'day') && isUserSelectedDate
          ? { d: selectedDate.toISOString() }
          : {}),
        ...(selectedInsurance
          ? { in: selectedInsurance.id, inpt: selectedInsurance.paymentType }
          : {}),
      }),
    })
  }, [
    currentLanguage,
    history,
    selectedLocation,
    searchTarget,
    isUserSelectedNode,
    selectedLanguage,
    selectedGender,
    selectedTimeRanges,
    selectedPatientAgeGroup,
    selectedDate,
    selectedAppointmentTypes,
    selectedAppointmentListVariant,
    isUserSelectedDate,
    selectedInsurance,
    appointmentSearchMode,
    defaultNode,
    selectedStandardScheduleOption,
  ])
}

// Retain these GET parameters for marketing
const whitelist = [
  'gclid',
  'dclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_id',
]

const getWhitelistedSearch = (parsed: qs.ParsedQs): qs.ParsedQs => {
  const emptyObj: qs.ParsedQs = {}
  const whitelisted = whitelist.concat(externalUrlParameters.map((param) => param.toLowerCase()))
  for (const key in parsed) {
    if (whitelisted.includes(key.toLowerCase())) {
      emptyObj[key] = parsed[key]
    }
  }

  return emptyObj
}

export default useSearchHistory
