import { getLanguage } from '../i18n/i18n'

export const getAV3HomeUrl = () => {
  let url =
    window.location.origin +
    window.location.href.includes(process.env.REACT_APP_BASE_BATH ?? '/av3')
      ? process.env.REACT_APP_BASE_BATH ?? '/av3'
      : ''
  url += '?lang=' + getLanguage()
  return url
}

export const externalUrlParameters = [
  'marketingCampaignCode',
  'noLanding',
  'parseurlParams',
  'sourceService',
]

export const filterExternalUrlParameters = (queryString: string): URLSearchParams => {
  const params = new URLSearchParams(queryString)
  const filteredParams = new URLSearchParams()
  params.forEach((value, key) => {
    if (externalUrlParameters.includes(key)) {
      filteredParams.append(key, value)
    }
  })
  return filteredParams
}
