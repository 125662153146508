import { atom } from 'recoil'

import { AppointmentType, SourceService } from '../../__generated__/api'

export type AppointmentId = number | string

export const selectedAppointmentIdAtom = atom<AppointmentId | undefined>({
  key: 'common-selectedAppointmentIdAtom',
  default: undefined,
})

export const selectedAppointmentTypeAtom = atom<AppointmentType | undefined>({
  key: 'common-selectedAppointmentTypeAtom',
  default: undefined,
})

export const authenticationModalOpenAtom = atom<boolean>({
  key: 'common-authenticationModalOpenAtom',
  default: false,
})

export const selectedAppointmentLengthAtom = atom<number | 'default'>({
  key: 'common-selectedAppointmentLengthAtom',
  default: 'default',
})

export const generalErrorMessageAtom = atom<string | undefined>({
  key: 'common-generalErrorMessageAtom',
  default: undefined,
})

export const selectedAppointmentNodeIdAtom = atom<string | undefined>({
  key: 'common-selectedAppointmentNodeAtom',
  default: undefined,
})

export const selectedAppointmentNodeConnectionIdAtom = atom<string | undefined>({
  key: 'common-selectedAppointmentNodeConnectionIdAtom',
  default: undefined,
})

export const selectedAppointmentServiceIdAtom = atom<number | undefined>({
  key: 'common-selectedAppointmentServiceAtom',
  default: undefined,
})

/**
 * The modal can be in one of three states: closed, open, or displaying subevents (non-navigation events) of a given carePlanGuid
 */
export const omLightModalAtom = atom<'closed' | 'open' | string>({
  key: 'common-omLightModalAtom',
  default: 'closed',
})

export const isFromAppAtom = atom<boolean>({
  key: 'common-isFromAppAtom',
  default: false,
})

export const isFromRelayAppAtom = atom<boolean>({
  key: 'common-isFromRelayAppAtom',
  default: false,
})

export const isFromOMAtom = atom<boolean>({
  key: 'common-isFromOMAtom',
  default: false,
})

export const sourceServiceAtom = atom<SourceService | undefined>({
  key: 'common-sourceServiceAtom',
  default: undefined,
})

/**
 * DO NOT set a new value directly as this atom has side effects
 * Use selector `selectedInsuranceSelector`
 * @private
 */
export const selectedInsuranceAtom = atom<number | 'other' | null>({
  key: 'common-selectedInsuranceAtom',
  default: null,
})

/**
 * DO NOT set a new value directly as this atom has side effects
 * Use selector `selectedInsuranceSelector`
 * @private
 */
export const selectedSearchInsuranceAtom = atom<number | 'other' | null>({
  key: 'common-selectedSearchInsuranceAtom',
  default: null,
})

export const selectedInsurancePayerAtom = atom<string | null>({
  key: 'common-selectedInsurancePayerAtom',
  default: null,
})

export const isRedirectedFromInsuranceProviderAtom = atom<boolean>({
  key: 'common-isRedirectedFromInsuranceProviderAtom',
  default: false,
})

export const scheduledMaintenanceMessageAtom = atom<string | undefined>({
  key: 'common-scheduledMaintenanceMessage',
  default: undefined,
})

export const insuranceModalOpenAtom = atom<boolean>({
  key: 'common-insuranceModalOpenAtom',
  default: false,
})

export const preSelectedInsuranceIdAtom = atom<number | 'other' | undefined>({
  key: 'common-preSelectedInsuranceIdAtom',
  default: undefined,
})

export const isOHCAtom = atom<boolean>({
  key: 'common-isOHC',
  default: false,
})

export const marketingCampaignCodeAtom = atom<string | undefined>({
  key: 'common-marketingCampaignCode',
  default: undefined,
})
