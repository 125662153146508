import { Event } from '@mehilainen/mds-customer'
import { PollH } from '@mehilainen/mds-customer/icons'
import { eHoitoEventNavigation } from '@mehilainen/omamehilainen-types/lib/ehoito'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'

import { omLightModalAtom } from '../../../state/common/atoms'
import { useOHC } from '../../hooks/useOHC'
import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'

import NoEvents from './NoEvents'

interface Props {
  events?: eHoitoEventNavigation[]
  showNoEvents?: boolean
  onClick?(): void
}

const EventButton: React.FC<Props> = ({ events, showNoEvents = false, onClick }) => {
  const { i18n } = useTranslation()
  const setOmLightModal = useSetRecoilState(omLightModalAtom)
  const { isOHCSide, ohcAllowedStatus } = useOHC()

  if (!isOHCSide || ohcAllowedStatus !== 'allowed') {
    return null
  }

  return (
    <>
      {events && events.length > 0 ? (
        <ColumnFlex $gap={scale(1)}>
          {events.map((event) => (
            <Event
              key={event.id}
              startIcon={<PollH />}
              title={event.i18n?.name[i18n.language]}
              description={event.i18n?.description[i18n.language]}
              onClick={() => {
                setOmLightModal(event.carePlanGuid)
                onClick?.()
              }}
            />
          ))}
        </ColumnFlex>
      ) : showNoEvents ? (
        <NoEvents />
      ) : (
        <></>
      )}
    </>
  )
}

export default EventButton
