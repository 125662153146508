import styled from '@emotion/styled'
import { Error100, Error400, Error500 } from '@mehilainen/mds-customer/colors'
import { Times } from '@mehilainen/mds-customer/icons'
import { Alert, Snackbar } from '@mui/material'
import { Form, Formik, FormikTouched } from 'formik'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { ReserveResult, SupportedLanguage } from '../../../__generated__/api'
import BookingInstructions from '../../../common/components/BookingInstructions/BookingInstructions'
import {
  DefaultButton,
  DefaultLoadingButton,
} from '../../../common/components/DefaultButton/DefaultButton'
import Divider from '../../../common/components/Divider/Divider'
import { CenteredColumnFlex, ColumnFlex, RowFlex } from '../../../common/components/Layout/Layout'
import LockReleaseWarning from '../../../common/components/LockReleaseWarning/LockReleaseWarning'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { useInsurancePayerOption } from '../../../common/hooks/useInsurance'
import api from '../../../common/services/api'
import * as Analytics from '../../../common/utils/analytics'
import { scale } from '../../../common/utils/scale'
import {
  isFromAppAtom,
  isFromOMAtom,
  isOHCAtom,
  marketingCampaignCodeAtom,
  selectedAppointmentServiceIdAtom,
  sourceServiceAtom,
} from '../../../state/common/atoms'
import { bookedForSelfAtom } from '../../../state/reserve/atoms'
import {
  reservationConfirmationEmailAtom,
  reservationConfirmationContactByPhoneAtom,
} from '../../../state/user/atoms'
import useReserveState from '../hooks/useReserveState'
import { additionalInformationMaxLength, createValidationSchema } from '../utils/form'
import {
  isLahiTapiolaInformation,
  isOccupationalAccidentInformation,
  isTrafficAccidentInformation,
  mapOccupationalAccidentInformation,
  mapTrafficAccidentInformation,
} from '../utils/insurance'

import InsuranceCallout from './InsuranceCallout'
import ReserveAdditionalInformationForm from './ReserveAdditionalInformationForm'
import ReserveAddressInformationForm from './ReserveAddressInformationForm'
import ReserveAppointmentSummary from './ReserveAppointmentSummary'
import ReserveContactInformationForm from './ReserveContactInformationForm'
import ReserveMarketingPermissions from './ReserveMarketingPermissions'
import ReserveServiceMessagePermissions from './ReserveServiceMessagePermissions'

const Container = styled(ColumnFlex)`
  align-items: center;
  min-height: 100%;
`

const Wrapper = styled.div`
  max-width: 688px;
  margin: 25px 16px 0;
`

const HeaderText = styled(Text)`
  margin-bottom: ${scale(1)};
`

const SummaryContainer = styled(ColumnFlex)`
  width: 100%;
  background-color: white;
  align-items: stretch;
  margin-bottom: ${scale(3)};
`

const StyledForm = styled(Form)`
  margin-top: 24px;
`

const ButtonContainer = styled(CenteredColumnFlex)`
  margin-top: ${scale(3)};
  gap: ${scale(2)};
`

const FormInfoText = styled(Text)`
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
`

const ErrorBanner = styled(RowFlex)`
  width: auto;
  background-color: ${Error100};
  border: 1px solid ${Error400};
  border-radius: 8px;
  gap: 35px;
  margin-bottom: 25px;
  padding: 8px 25px;
`

const PrivacyPolicyLink = styled(Text)`
  display: block;
  margin-bottom: 24px;
  margin-top: 8px;
`

const BookingInstructionsContainer = styled.div`
  margin-top: ${scale(1)};
`

const InsuranceCalloutContainer = styled.div`
  margin-top: ${scale(1)};
`

const StyledLockReleaseWarning = styled(LockReleaseWarning)`
  margin-bottom: ${scale(3)};
`

const StyledTimes = styled(Times)`
  width: 25px;
  height: 25px;
  color: ${Error400};
`

interface FocusErrorProps {
  isSubmitting: boolean
  isValidating: boolean
  onFocus: () => void
}

const FocusErrorOnSubmit: React.FC<React.PropsWithChildren<FocusErrorProps>> = ({
  isSubmitting,
  isValidating,
  onFocus,
}) => {
  if (!isValidating && isSubmitting) {
    setTimeout(() => onFocus(), 200)
  }
  return null
}

interface Props {
  onReserved(reservation: ReserveResult): void
  onBack(): void
}

const ReserveForm: React.FC<React.PropsWithChildren<Props>> = ({ onReserved, onBack }) => {
  const {
    appointmentId,
    appointmentTitle,
    loginStatus,
    loggedInUser,
    selectedAppointmentLength,
    selectedAppointmentType,
    selectedUser,
    selectedNodeId,
    selectedNodeConnectionId,
    appointmentInfo,
    node,
    nodePending,
    selectedInsuranceContractId,
    selectedInsurancePayerId,
    insuranceAdditionalDetails,
    paymentType,
    bookingInstructions,
    lockReleaseDateTime,
    extendLock,
    extended,
  } = useReserveState()
  const isMobile = useIsMobile()

  const { insurancePayerOption } = useInsurancePayerOption(
    selectedInsuranceContractId,
    selectedInsurancePayerId
  )

  const { t, i18n } = useTranslation()
  const [reservationError, setReservationError] = useState<null | string>(null)

  const setEmail = useSetRecoilState(reservationConfirmationEmailAtom)
  const setContactByPhone = useSetRecoilState(reservationConfirmationContactByPhoneAtom)
  const selectedServiceId = useRecoilValue(selectedAppointmentServiceIdAtom)
  const isFromApp = useRecoilValue(isFromAppAtom)
  const isFromOM = useRecoilValue(isFromOMAtom)
  const sourceService = useRecoilValue(sourceServiceAtom)

  const duration = selectedAppointmentLength === 'default' ? undefined : selectedAppointmentLength
  const reserveToSelf = loggedInUser && loggedInUser.omUid === selectedUser.omUid

  const errorFocusRef = useRef<HTMLDivElement>(null)

  const validationSchema = createValidationSchema(t)

  const bookedForSelf = useRecoilValue(bookedForSelfAtom)

  const isOHC = useRecoilValue(isOHCAtom)

  const marketingCampaignCode = useRecoilValue(marketingCampaignCodeAtom)

  const initialValues = {
    showAddressFields:
      !selectedUser.hasAddress ||
      selectedUser.address !== undefined ||
      loginStatus === 'impersonated',
    ssn: selectedUser?.ssn ?? '',
    firstName: selectedUser?.firstName ?? '',
    lastName: selectedUser?.lastName ?? '',
    phone: selectedUser?.phone ?? '',
    sendAttachments: true,
    sendConfirmationSms: false,
    email: selectedUser?.email ?? '',
    additionalInformation: '',
    address: selectedUser?.address?.address || '',
    city: selectedUser?.address?.postOffice || '',
    postalCode: selectedUser?.address?.postalCode || '',
    marketingPermitEmail: selectedUser?.marketingPermits?.permitEmail,
    marketingPermitPhone: selectedUser?.marketingPermits?.permitPhone,
    serviceMessagePermit: selectedUser?.serviceMessagePermit,
    minorDisclosureOfInformation: false,
    discountCode: marketingCampaignCode ?? '',
  }

  const onReserveSubmit = useCallback(
    async (values: typeof initialValues) => {
      await api.v1
        .reserve({
          appointmentId,
          isFromApp,
          isFromOM,
          sourceService,
          firstName: values.firstName,
          lastName: values.lastName,
          ssn: values.ssn,
          email: values.email,
          phone: values.phone,
          nodeId: selectedNodeId,
          connectionId: selectedNodeConnectionId,
          appointmentReason: values.additionalInformation,
          sendAttachments: values.sendAttachments,
          contactByEmail: true,
          reserveToOmUid:
            loggedInUser && loggedInUser.omUid !== selectedUser.omUid
              ? selectedUser.omUid
              : undefined,
          contactBySms: values.sendConfirmationSms,
          lang: i18n.language as SupportedLanguage,
          appointmentType: selectedAppointmentType,
          duration: duration,
          serviceId: selectedServiceId,
          registration: values.showAddressFields
            ? {
                address: values.address,
                postalCode: values.postalCode,
                postOffice: values.city,
              }
            : undefined,
          marketingPermits: reserveToSelf
            ? {
                permitPhone: values.marketingPermitPhone ?? false,
                permitEmail: values.marketingPermitEmail ?? false,
              }
            : undefined,
          serviceMessagePermit: reserveToSelf ? values.serviceMessagePermit ?? false : undefined,
          ...(reserveToSelf || !loggedInUser
            ? { minorDisclosureOfInformation: values.minorDisclosureOfInformation }
            : {}),
          ...(selectedInsuranceContractId && selectedInsurancePayerId
            ? {
                insurance: {
                  insuranceId: selectedInsuranceContractId,
                  paymentTypeId: selectedInsurancePayerId,
                  ...(isTrafficAccidentInformation(insuranceAdditionalDetails)
                    ? {
                        trafficAccidentInformation: mapTrafficAccidentInformation(
                          insuranceAdditionalDetails
                        ),
                      }
                    : isOccupationalAccidentInformation(insuranceAdditionalDetails)
                    ? {
                        occupationalAccidentInformation: mapOccupationalAccidentInformation(
                          insuranceAdditionalDetails
                        ),
                      }
                    : isLahiTapiolaInformation(insuranceAdditionalDetails)
                    ? {
                        lahiTapiolaInformation: {
                          permitId: insuranceAdditionalDetails.permitId,
                        },
                      }
                    : {}),
                },
              }
            : {}),
          paymentType,
          ...(values.discountCode ? { discountCode: values.discountCode } : {}),
          isOhc: isOHC,
        })
        .then((res) => {
          if (res.error) {
            if (res.error.message?.translatedMessage) {
              setReservationError(res.error.message?.translatedMessage)
              return
            }
            setReservationError(t('common.error'))
            return
          }

          const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

          if (isFromApp && appointmentInfo) {
            Analytics.trackMobileConfirm({
              appointmentInfo: {
                time: appointmentInfo.date,
                type: selectedAppointmentType,
                specialistName: appointmentInfo.practitionerName,
                practitionerId: appointmentInfo.practitionerId,
                locationName: appointmentInfo.locationName,
                locationId: appointmentInfo.locationId,
              },
              bookedFor: bookedForSelf ? 'Myself' : 'Other',
              duration: selectedAppointmentLength === 'default' ? 'regular' : 'extended',
              isOHC,
              isDental: appointmentInfo.appointmentSource === 'AssisDent',
            })
          }

          return isFromApp && appointmentInfo
            ? sleep(1000).then(() => {
                // Wait for datalayer
                onReserved(res.data)
              })
            : onReserved(res.data)
        })
    },
    [
      appointmentId,
      isFromApp,
      isFromOM,
      sourceService,
      selectedNodeId,
      selectedNodeConnectionId,
      loggedInUser,
      selectedUser.omUid,
      i18n.language,
      selectedAppointmentType,
      duration,
      selectedServiceId,
      reserveToSelf,
      selectedInsuranceContractId,
      selectedInsurancePayerId,
      insuranceAdditionalDetails,
      paymentType,
      onReserved,
      t,
      bookedForSelf,
      appointmentInfo,
      selectedAppointmentLength,
      isOHC,
    ]
  )

  function blurHandler(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    touched: FormikTouched<typeof initialValues>,
    handleBlur: {
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void
    }
  ) {
    if (Object.keys(touched).includes(e.target.id)) {
      handleBlur(e)
    }
  }

  return (
    <Container>
      <Wrapper>
        <StyledLockReleaseWarning
          releaseDateTime={lockReleaseDateTime}
          appointmentDate={appointmentInfo?.date}
          extendLock={extendLock}
          extended={extended}
        />

        <SummaryContainer>
          <ReserveAppointmentSummary
            appointmentTitle={appointmentTitle}
            appointmentInfo={appointmentInfo}
            node={node}
            nodePending={nodePending}
          />
          {bookingInstructions && (
            <BookingInstructionsContainer>
              <BookingInstructions instructions={bookingInstructions} />
            </BookingInstructionsContainer>
          )}
          {selectedInsuranceContractId && (
            <InsuranceCalloutContainer>
              <InsuranceCallout insuranceId={selectedInsuranceContractId} />
            </InsuranceCalloutContainer>
          )}
        </SummaryContainer>

        <HeaderText $size={500} $height="Medium" $weight="Medium" as="h1">
          {isMobile
            ? t('component.reserveForm.reserveFormReserveHeadingMobile')
            : t('component.reserveForm.reserveFormReserveHeading')}
        </HeaderText>

        <Formik<typeof initialValues>
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={onReserveSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            submitForm,
            setValues,
            isSubmitting,
            isValidating,
            isValid,
            setFieldValue,
          }) => (
            <StyledForm>
              <FormInfoText $size={300}>
                {t('component.reserveContactInformationForm.requiredFieldsInstruction')}
              </FormInfoText>
              {!isValid && (
                <ErrorBanner tabIndex={0} ref={errorFocusRef}>
                  <StyledTimes />
                  <Text $height="Large" $weight={'Bold'} $size={300} $color={Error500}>
                    {t('component.reserveContactInformationForm.formFieldErrors', {
                      count: Object.keys(errors).length,
                    })}
                  </Text>
                </ErrorBanner>
              )}

              <Divider />

              <ReserveAddressInformationForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={(
                  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
                ) => {
                  blurHandler(e, touched, handleBlur)
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setValues={setValues as any}
              />

              <Divider />

              <ReserveContactInformationForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={(
                  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
                ) => {
                  blurHandler(e, touched, handleBlur)
                }}
                setFieldValue={setFieldValue}
                disableMinorDisclosure={!reserveToSelf}
                insurancePayerOption={insurancePayerOption}
                hasAttachments={Boolean(bookingInstructions?.attachments)}
                isCallbackAppointment={Boolean(appointmentInfo?.isCallbackAppointment)}
              />

              <Divider />

              <ReserveAdditionalInformationForm
                additionalInformationMaxLength={additionalInformationMaxLength}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={(
                  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
                ) => {
                  blurHandler(e, touched, handleBlur)
                }}
                isOHC={isOHC}
              />
              <Divider />
              {loginStatus !== 'impersonated' &&
                Boolean(selectedUser.ssn) &&
                reserveToSelf &&
                selectedUser?.marketingPermits !== undefined &&
                (!selectedUser?.marketingPermits.permitEmail ||
                  !selectedUser?.marketingPermits.permitPhone) && (
                  <>
                    <ReserveMarketingPermissions
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      showEmailPermitOption={selectedUser?.marketingPermits.permitEmail !== true}
                      showPhonePermitOption={selectedUser?.marketingPermits.permitPhone !== true}
                    />
                    <Divider />
                  </>
                )}
              {loginStatus !== 'impersonated' &&
                reserveToSelf &&
                !selectedUser?.serviceMessagePermit && (
                  <>
                    <ReserveServiceMessagePermissions
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                    />
                    <Divider />
                  </>
                )}

              <Text $size={200} $weight="Regular" $height="Large" as="p">
                {t('component.reserveForm.privacyPolicy.text')}
              </Text>
              <PrivacyPolicyLink
                as="a"
                $size={200}
                $weight="Medium"
                href={t('component.reserveForm.privacyPolicy.link')}
                target="_blank"
                rel="noreferrer"
              >
                {t('component.reserveForm.privacyPolicy.linkText')}
              </PrivacyPolicyLink>

              <Text $size={200} $weight="Regular" $height="Large" as="p">
                {t('component.reserveForm.cancellationNotes')}
              </Text>

              <ButtonContainer>
                <DefaultLoadingButton
                  type="button"
                  onClick={() => {
                    setEmail(values.email)
                    setContactByPhone(values.sendConfirmationSms)
                    submitForm()
                  }}
                  data-cy="reserveForm-reserveButton"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('component.reserveForm.confirmAppointment')}
                </DefaultLoadingButton>
                <DefaultButton type={'button'} variant="outlined" onClick={onBack}>
                  {t('common.back')}
                </DefaultButton>
                <FocusErrorOnSubmit
                  isSubmitting={isSubmitting}
                  isValidating={isValidating}
                  onFocus={() => errorFocusRef?.current?.focus()}
                />
              </ButtonContainer>
            </StyledForm>
          )}
        </Formik>

        <Snackbar
          open={Boolean(reservationError)}
          onClose={() => setReservationError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error">{reservationError}</Alert>
        </Snackbar>
      </Wrapper>
    </Container>
  )
}

export default ReserveForm
