import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import {
  AppointmentSearchResult,
  CallbackSearchResult,
  SearchAppointmentSuggestion,
  SearchAppointmentSuggestionType,
  SupportedLanguage,
} from '../../../__generated__/api'
import { useApi } from '../../../common/hooks/useApi'
import { useOHC } from '../../../common/hooks/useOHC'
import api from '../../../common/services/api'
import { ApiError } from '../../../common/utils/error/utils'
import { AppointmentSearchMode, appointmentSearchModeAtom } from '../../../state/search/atoms'
import { allLocationsSelection } from '../../../state/search/atoms'
import {
  nodeSearchParamsSelector,
  practitionerSearchParamsSelector,
} from '../../../state/search/selectors'

import useSearchTarget, { SearchTargetValue } from './useSearchTarget'

export const getDateAfter = (appointments: AppointmentSearchResult[]): Date => {
  if (appointments.length === 0) {
    return dayjs().add(1, 'day').startOf('day').toDate()
  }

  return dayjs(
    appointments.map((appointment) => new Date(appointment.time).getTime()).sort((a, b) => b - a)[0]
  )
    .add(1, 'day')
    .startOf('day')
    .toDate()
}

const useSearchResults = (
  getSuggestions?: boolean
): {
  appointments: Array<AppointmentSearchResult | CallbackSearchResult>
  suggestions: SearchAppointmentSuggestion[] | null
  pending: boolean
  error: ApiError | null
} => {
  const { i18n } = useTranslation()
  const nodeSearchParams = useRecoilValue(nodeSearchParamsSelector)
  const practitionerSearchParams = useRecoilValue(practitionerSearchParamsSelector)
  const { searchTarget } = useSearchTarget()
  const appointmentSearchMode = useRecoilValue(appointmentSearchModeAtom)
  const { isOHCSide, ohcAllowedStatus } = useOHC()

  const isPractitionerSearch = searchTarget.value === SearchTargetValue.Practitioner
  const isNodeSearch = searchTarget.value === SearchTargetValue.Node
  const isCallbackSearch = appointmentSearchMode === AppointmentSearchMode.CALLBACK

  const shouldMakeRequest = isOHCSide ? ohcAllowedStatus === 'allowed' : true
  const shouldMakePractitionerRequest =
    isPractitionerSearch &&
    shouldMakeRequest &&
    practitionerSearchParams &&
    Boolean(practitionerSearchParams?.practitionerId)

  const {
    data: nodeSearchResults,
    pending: nodeSearchPending,
    error: nodeSearchError,
  } = useApi(
    api.v1.nodeAppointmentSearch,
    {
      ...nodeSearchParams,
      lang: i18n.language as SupportedLanguage,
    },
    { appointments: [], suggestions: null },
    isNodeSearch && shouldMakeRequest
  )

  const { data: nodeSearchSuggestions } = useApi(
    api.v1.nodeSearchSuggestions,
    {
      types: [
        SearchAppointmentSuggestionType.Date,
        SearchAppointmentSuggestionType.Node,
        ...(nodeSearchParams.locationIds !== allLocationsSelection
          ? [SearchAppointmentSuggestionType.Location]
          : []),
      ],
      datesAfter: getDateAfter(nodeSearchResults.appointments).toISOString(),
      nodeSearch: {
        ...nodeSearchParams,
        lang: i18n.language as SupportedLanguage,
      },
    },
    null,
    isNodeSearch && !nodeSearchPending && getSuggestions
  )

  const { data: practitionerSearchResults, pending: practitionerSearchPending } = useApi(
    api.v1.practitionerAppointmentSearch,
    {
      ...practitionerSearchParams!,
      isOhc: isOHCSide,
      lang: i18n.language as SupportedLanguage,
    },
    { appointments: [], suggestions: null },
    shouldMakePractitionerRequest && !isCallbackSearch
  )

  const { data: callbackSearchResults, pending: callbackSearchPending } = useApi(
    api.v1.practitionerCallbackSearch,
    {
      practitionerId: practitionerSearchParams ? practitionerSearchParams.practitionerId : 0,
      startDate: practitionerSearchParams ? practitionerSearchParams.startDate : '',
      lang: i18n.language as SupportedLanguage,
    },
    { appointments: [] },
    shouldMakePractitionerRequest && isCallbackSearch
  )

  return {
    appointments: isCallbackSearch
      ? callbackSearchResults.appointments
      : isPractitionerSearch
      ? practitionerSearchResults.appointments
      : nodeSearchResults.appointments,
    suggestions: isCallbackSearch
      ? null
      : isPractitionerSearch
      ? practitionerSearchResults.suggestions
      : nodeSearchSuggestions,
    pending: isCallbackSearch
      ? callbackSearchPending
      : isPractitionerSearch
      ? practitionerSearchPending
      : nodeSearchPending,
    error: nodeSearchError,
  }
}

export default useSearchResults
