import { useQuery } from '@tanstack/react-query'
import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { v4 as uuid } from 'uuid'

import { HttpResponse, RequestParams } from '../../__generated__/api'
import { scheduledMaintenanceMessageAtom } from '../../state/common/atoms'
import api from '../services/api'
import MaintenanceError from '../utils/error/MaintenanceError'

import { useAsyncError } from './useAsyncError'
import { useDeepCompareEffect } from './useDeepCompare'

/* FIXME: Caching API calls causes errors in React 18 strict mode, disabled for now.
const apiCache = new Map()

const cachedApiRequest = <ParamType, ResultType, ErrorType extends { name: string }>(
  apiCall: (
    params: ParamType,
    additionalParams: RequestParams
  ) => Promise<HttpResponse<ResultType, ErrorType>>,
  params: ParamType,
  additionalParams: RequestParams
) => {
  const apiCacheKey = JSON.stringify({ route: apiCall.name, params })
  let apiRequest: Promise<HttpResponse<ResultType, ErrorType>>
  if (apiCache.has(apiCacheKey)) {
    apiRequest = apiCache.get(apiCacheKey)
  } else {
    apiRequest = new Promise(async (resolve, reject) => {
      try {
        const result = await apiCall(params, additionalParams)
        resolve(result)
      } catch (e) {
        reject(e)
      } finally {
        apiCache.delete(apiCacheKey)
      }
    })
    apiCache.set(apiCacheKey, apiRequest)
  }
  return apiRequest
}
 */

export const useApiInternal = <ParamType, ResultType, ErrorType extends { name: string }>(
  apiCall: (
    params: ParamType,
    additionalParams: RequestParams
  ) => Promise<HttpResponse<ResultType, ErrorType>>,
  params: ParamType,
  setData: (data: ResultType) => void,
  reset: () => void,
  cancelToken: string,
  makeApiCall = true
): {
  pending: boolean
  error: ErrorType | undefined
} => {
  const { i18n } = useTranslation()
  const [pending, setPending] = useState<boolean>(true)
  const [error, setError] = useState<ErrorType>()
  const throwError = useAsyncError()
  const setScheduledMaintenanceMessage = useSetRecoilState(scheduledMaintenanceMessageAtom)

  useDeepCompareEffect(() => {
    cancelToken && api.http.abortRequest(cancelToken)
    setPending(true)
    setError(undefined)

    if (!makeApiCall) {
      reset()
      return
    }

    apiCall(params, {
      headers: { 'Accept-Language': i18n.language },
      cancelToken,
    })
      .then((res) => {
        const scheduledMaintenanceMessage = res.headers.get('x-scheduled-maintenance')
        if (scheduledMaintenanceMessage) {
          setScheduledMaintenanceMessage(scheduledMaintenanceMessage)
        }

        if (res.error) {
          throw res.error
        }
        setData(res.data)
        setPending(false)
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          return
        }
        if (err.statusCode === 503) {
          throwError(new MaintenanceError(err.message))
          return
        }
        reset()
        setError(err)
        setPending(false)
      })
  }, [apiCall, makeApiCall, cancelToken, params])

  return {
    pending,
    error,
  }
}

export const useApi2 = <ParamType, ResultType>(
  apiCall: (
    params: ParamType,
    additionalParams: RequestParams
  ) => Promise<HttpResponse<ResultType>>,
  params: ParamType,
  defaultValue: ResultType,
  makeApiCall = true
): {
  data: ResultType
  pending: boolean
  error: Error | null
} => {
  const {
    data: data,
    isPending: pending,
    error: error,
  } = useQuery({
    queryKey: [apiCall.name, params],
    queryFn: () => (makeApiCall ? apiCall(params, {}) : Promise.resolve({ data: defaultValue })),
  })
  if (!data) {
    return {
      data: defaultValue,
      pending: false,
      error: error,
    }
  }

  return {
    data: data.data,
    pending: pending,
    error,
  }
}

export const useApi = <ParamType, ResultType, ErrorType extends { name: string }>(
  apiCall: (
    params: ParamType,
    additionalParams: RequestParams
  ) => Promise<HttpResponse<ResultType, ErrorType>>,
  params: ParamType,
  defaultValue: ResultType,
  makeApiCall = true
): {
  data: ResultType
  pending: boolean
  error: ErrorType | undefined
} => {
  const cancelToken = useMemo(() => uuid(), [])
  const [data, setData] = useState<ResultType>(defaultValue)
  const { error, pending } = useApiInternal(
    apiCall,
    params,
    setData,
    () => setData(defaultValue),
    cancelToken,
    makeApiCall
  )

  return {
    data,
    pending,
    error,
  }
}

export const useApiWithTransform = <
  ParamType,
  ResultType,
  ErrorType extends { name: string },
  TransformedType,
>(
  apiCall: (
    params: ParamType,
    additionalParams: RequestParams
  ) => Promise<HttpResponse<ResultType, ErrorType>>,
  params: ParamType,
  defaultValue: TransformedType,
  transform: (data: ResultType) => TransformedType,
  makeApiCall = true
): {
  data: TransformedType
  pending: boolean
  error: ErrorType | undefined
} => {
  const cancelToken = useMemo(() => uuid(), [])
  const [data, setData] = useState<TransformedType>(defaultValue)
  const { error, pending } = useApiInternal(
    apiCall,
    params,
    (res) => setData(transform(res)),
    () => setData(defaultValue),
    cancelToken,
    makeApiCall
  )

  return {
    data,
    pending,
    error,
  }
}

export const useApiOnDemand = <ParamType, ResultType, ErrorType extends { name: string }>(
  apiCall: (
    params: ParamType,
    additionalParams: RequestParams
  ) => Promise<HttpResponse<ResultType, ErrorType>>,
  defaultValue: ResultType
): {
  data: ResultType
  pending: boolean
  error: ErrorType | undefined
  update: (params: ParamType) => void
  reset: () => void
} => {
  const { i18n } = useTranslation()
  const cancelToken = useMemo(() => uuid(), [])
  const [pending, setPending] = useState<boolean>(false)
  const [error, setError] = useState<ErrorType>()
  const [data, setData] = useState<ResultType>(defaultValue)
  const throwError = useAsyncError()

  const update = useCallback(
    (params: ParamType) => {
      cancelToken && api.http.abortRequest(cancelToken)
      setPending(true)
      apiCall(params, {
        headers: { 'Accept-Language': i18n.language },
        cancelToken,
      })
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          setData(res.data)
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            return
          }
          if (err.statusCode === 503) {
            throwError(new MaintenanceError(err.message))
            return
          }
          setError(err)
        })
        .then(() => setPending(false)) // Finally caused IE problems
    },
    [apiCall, cancelToken, i18n.language, throwError]
  )

  const reset = useCallback(() => setData(defaultValue), [defaultValue])

  return {
    data,
    pending,
    error,
    update,
    reset,
  }
}
