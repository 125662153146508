import styled from '@emotion/styled'
import { FormControl } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { useState } from 'react'

import { Text } from '../Typography/Typography'

const Tooltip = styled.div`
  margin-top: 16px;
`

interface Props {
  legend: string
  expanded: boolean
  tooltip?: string
  tooltipLink?: { link: string; text: string }
  labelId?: string
}

const Fieldset: React.FC<React.PropsWithChildren<Props>> = ({
  legend,
  children,
  tooltip,
  tooltipLink,
  expanded: expandedProp = false,
}) => {
  const [expanded, setExpanded] = useState<boolean>(expandedProp)

  return (
    <FormControl>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary>{legend}</AccordionSummary>
        <AccordionDetails>
          {children}
          {tooltip && (
            <Tooltip>
              <Text $size={200} $height="Large">
                {tooltip}
              </Text>
              {tooltipLink && (
                <div>
                  <Text $size={200} $height="Large" as="a" href={tooltipLink.link} target="_blank">
                    {tooltipLink.text}
                  </Text>
                </div>
              )}
            </Tooltip>
          )}
        </AccordionDetails>
      </Accordion>
    </FormControl>
  )
}

export default Fieldset
