import { eHoitoSubEventAppointment } from '@mehilainen/omamehilainen-types/lib/ehoito'

export const getAppointmentUrlFromEventData = (eventData: eHoitoSubEventAppointment): string => {
  if (eventData.url) {
    return eventData.url
  }

  const parseRoute = `${process.env.REACT_APP_AV_BASE_URL}/parse/tt:1/`

  if (eventData.practitioner.practitionerId) {
    return `${parseRoute}lkri:${eventData.practitioner.practitionerId}`
  }

  if (eventData.etns) {
    if (
      ['tth', 'ttht', 'tthm', 'tths'].includes(eventData.etns?.toLowerCase()) &&
      Number(eventData.duration)
    ) {
      switch (Number(eventData.duration)) {
        case 30:
          return `${parseRoute}alapalvelu:${eventData.etns},1`
        case 60:
          return `${parseRoute}alapalvelu:${eventData.etns},2`
        case 90:
          return `${parseRoute}alapalvelu:${eventData.etns},3`
        case 120:
          return `${parseRoute}alapalvelu:${eventData.etns},4`
      }
    }

    return `${parseRoute}etns:${eventData.etns}`
  }

  return parseRoute
}
