import { QueryClient } from '@tanstack/react-query'

class QueryClientProvider {
  private queryClient: QueryClient

  constructor() {
    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 20 * 1000,
        },
      },
    })
  }

  getClient(): QueryClient {
    return this.queryClient
  }
}

export const queryClientProvider = new QueryClientProvider()
